import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import lottie from "lottie-web"
import { window } from "browser-monads-ts"

import animationCat from "../../content/assets/lottie/animation-cat-walk-table.json"
// import animationCatSitting from "../../content/assets/lottie/animation-cat-table.json"

import {
  RevealSlideUp,
  RevealSlideRight,
  RevealFlipUp,
  RevealFlipRight,
  RevealFade,
} from "../utils/reveal"

// import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

import HeroHome from "../partials/HeroHome"
// import Testimonials from "../partials/Testimonials"

type SkillsType = {
  name: string,
  years: number
}
interface HomePagePropTypes {
  data: any;
  location: any;
}
const HomePage = ({ data, location }: HomePagePropTypes) => {
  let skillsWebDevelopment: SkillsType[] = [
    {
      name: "Responsive web development",
      years: 15,
    },
    {
      name: "Web design",
      years: 8,
    },
    {
      name: "Blog/CMS",
      years: 15,
    },
    {
      name: "CSS",
      years: 22,
    },
    {
      name: "HTML",
      years: 25,
    },
    {
      name: "HTML5",
      years: 6,
    },
    {
      name: "JavaScript",
      years: 20,
    },
    {
      name: "TypeScript",
      years: 1,
    },
    {
      name: "React JS",
      years: 2,
    },
    {
      name: "Redux",
      years: 1,
    },
    {
      name: "Adobe Photoshop",
      years: 26,
    },
    {
      name: "Adobe Illustrator",
      years: 22,
    },
    {
      name: "Adobe XD",
      years: 2,
    },
    {
      name: "Sketch",
      years: 1,
    },
    {
      name: "mySQL",
      years: 18,
    },
  ]
  let skillsAppDevelopment: SkillsType[] = [
    {
      name: "Mobile app development",
      years: 8,
    },
    {
      name: "React Native JS",
      years: 1,
    },
    {
      name: "React JS",
      years: 2,
    },
    {
      name: "Swift",
      years: 6,
    },
    {
      name: "Objective-C",
      years: 4,
    },
    {
      name: "MongoDB (noSQL)",
      years: 3,
    },
  ]
  let skillsAnimation: SkillsType[] = [
    {
      name: "CSS animation",
      years: 10,
    },
    {
      name: "Adobe AFter Effects",
      years: 18,
    },
    {
      name: "Adobe Animation",
      years: 20,
    },
    {
      name: "3D Modeling/Animating",
      years: 15,
    },
    {
      name: "2D character animation",
      years: 10,
    },
    {
      name: "Spine",
      years: 2,
    },
  ]

  // * ----------- MOBILE --------
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= 768 ? true : false
  )
  const updateIsMobile = (m: boolean) => {
    // console.log("mobile:", m)
    setIsMobile(m)
  }

  // *********** LOTTIE CAT ***********
  // const [catSitting, setCatSitting] = useState(false)
  // const updateCatSitting = s => {
  //   setCatSitting(s)
  // }
  let lottieCatContainer = useRef<HTMLInputElement | null>(null)
  let catSectionStart = useRef<HTMLInputElement | null>(null)
  let catSectionStop = useRef<HTMLInputElement | null>(null)
  let catAnim: any = useRef(null)
  // let lottieCatSittingContainer = useRef()
  // let catSittingAnim = useRef(null)
  useEffect(() => {
    if (!catAnim.current && lottieCatContainer.current) {
      catAnim.current = lottie.loadAnimation({
        container: lottieCatContainer.current,
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData: animationCat,
      })

      return () => catAnim.current?.destroy()
    }
  }, [])
  // useEffect(() => {
  //   if (!catSittingAnim.current && lottieCatSittingContainer.current) {
  //     catSittingAnim.current = lottie.loadAnimation({
  //       container: lottieCatSittingContainer.current,
  //       renderer: "svg",
  //       loop: false,
  //       autoplay: false,
  //       animationData: animationCatSitting,
  //     })

  //     return () => catSittingAnim.current?.destroy()
  //   }
  // }, [])

  // * ----------- SCROLLING --------
  // let scrollStopTimeout = null
  useEffect(() => {
    function handleResize() {
      if (window !== undefined && window.innerWidth <= 768) {
        updateIsMobile(true)
      } else {
        updateIsMobile(false)
      }
    }
    function handleScroll() {
      // if (window !== undefined && window.innerWidth <= 768) {
      //   updateIsMobile(true)
      // } else {
      //   updateIsMobile(false)
      // }

      animateCatWalkTableScroll(window.scrollY)

      // ----- cat walking on scroll or sitting if not scrolling -----
      // console.log("handleScroll | catSitting", catSitting)
      // if (catSitting === true) {
      //   updateCatSitting(false)
      //   catSittingAnim.current?.stop()
      // }
      // if (scrollStopTimeout) {
      //   clearTimeout(scrollStopTimeout)
      // }
      // if (!scrollStopTimeout) {
      //   setTimeout(() => {
      //     scrollStopTimeout = null
      //     // console.log("TIMEOUT ------> CAT SITS")
      //     if (catSitting === false) {
      //       updateCatSitting(true)
      //       catSittingAnim.current?.play()
      //     }
      //   }, 1000)
      // }
    }
    if (window !== undefined) {
      window.addEventListener("scroll", handleScroll)
      window.addEventListener("resize", handleResize)
      return function cleanupListener() {
        window.removeEventListener("scroll", handleScroll)
        window.removeEventListener("resize", handleResize)
      }
    }
  })

  const animateCatWalkTableScroll = (scrollCurrent: any) => {
    // console.log("animateCatWalkTableScroll |  scrollCurrent:", scrollCurrent)
    if (
      catSectionStart.current !== undefined &&
      catSectionStart.current !== null &&
      catSectionStop.current !== undefined &&
      catSectionStop.current !== null
    ) {
      if (
        catSectionStart.current.offsetTop !== undefined &&
        catSectionStart.current.offsetTop !== null &&
        catSectionStop.current.offsetTop !== undefined &&
        catSectionStart.current.offsetTop !== null &&
        window !== undefined &&
        window.innerHeight !== undefined
      ) {
        let catScrollStart =
          catSectionStart.current.offsetTop - window.innerHeight / 2
        let catScrollStop =
          catSectionStop.current.offsetTop + window.innerHeight / 2
        // console.log(
        //   `animateCatWalkTableScroll |  cur: ${scrollCurrent}  catScrollStart: ${catScrollStart} catScrollStop: ${catScrollStop}`
        // )
        if (scrollCurrent > catScrollStart && scrollCurrent < catScrollStop) {
          let total = catScrollStop - catScrollStart
          let progress = scrollCurrent - catScrollStart
          let percent = progress / total
          animatebodymovin(catAnim, percent)
        }
      }
    }
  }

  const animatebodymovin = (catAnim: any, percent: number) => {
    // console.log("animatebodymovin |  catAnim:", catAnim.current)
    if (catAnim.current && catAnim.current.totalFrames !== undefined) {
      const maxFrames = catAnim.current.totalFrames
      const frame = Math.round(maxFrames * percent)
      catAnim.current.goToAndStop(frame, true)
    }
  }

  return (
    <Layout location={location} title={"ABC Interactive LLC"}>
      <SEO
        title="ABC Interactive LLC Providing Quality Website Application Development Services since 2005."
        keywords={[
          `website`,
          `app`,
          `development`,
          `react`,
          `gatsby`,
          `tailwind`,
          `mobile`,
          `tailwindcss`,
        ]}
        description="ABC Interactive LLC designing, building, launching, and maintaining quality websites since 2005. Services include modern website and app development. Educational games include Squirreled®, Squirreled World®, and Squirreled® Classroom."
      />

      <HeroHome />

      <section id="welcome" className="bg-white dark:bg-gray-800">
        <div className="container px-6 py-16 mx-auto">
          <div className="items-center lg:flex">
            <div className="lg:w-1/2">
              <RevealSlideUp>
                <h2 className="text-3xl font-bold text-gray-800  font-family-opensans  dark:text-gray-100">
                  Welcome to ABC Interactive LLC!
                </h2>
              </RevealSlideUp>
              <RevealSlideRight>
                <p className="mt-4 text-gray-600 dark:text-gray-400 font-family-opensans  lg:max-w-md">
                  ABC Interactive LLC is a small website and mobile app
                  development company located in USA. Founded in
                  2005 with decade of building engaging online experiences for
                  companies large and small.
                </p>
              </RevealSlideRight>

              <div className="flex items-center mt-6 -mx-2">
                <a
                  className="mx-2  
                  transition duration-200 transform 
                  hover:-translate-y-1 hover:scale-110"
                  href="https://www.linkedin.com/in/andrew-chew-9a3a84/"
                  aria-label="Linkden"
                >
                  <svg
                    className="w-5 h-5 text-gray-700 fill-current dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path fill="#0a66c2" d="M417.2 64H96.8C79.3 64 64 76.6 64 93.9V415c0 17.4 15.3 32.9 32.8 32.9h320.3c17.6 0 30.8-15.6 30.8-32.9V93.9C448 76.6 434.7 64 417.2 64zM183 384h-55V213h55v171zm-25.6-197h-.4c-17.6 0-29-13.1-29-29.5 0-16.7 11.7-29.5 29.7-29.5s29 12.7 29.4 29.5c0 16.4-11.4 29.5-29.7 29.5zM384 384h-55v-93.5c0-22.4-8-37.7-27.9-37.7-15.2 0-24.2 10.3-28.2 20.3-1.5 3.6-1.9 8.5-1.9 13.5V384h-55V213h55v23.8c8-11.4 20.5-27.8 49.6-27.8 36.1 0 63.4 23.8 63.4 75.1V384z" />
                  </svg>
                </a>

                <a
                  className="mx-2  
                  transition duration-200 transform 
                  hover:-translate-y-1 hover:scale-110"
                  href="https://github.com/abcinteractive"
                  aria-label="Github"
                >
                  <svg
                    className="w-5 h-5 text-gray-700 fill-current dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path fill="#000" d="M256 32C132.3 32 32 134.9 32 261.7c0 101.5 64.2 187.5 153.2 217.9 1.4.3 2.6.4 3.8.4 8.3 0 11.5-6.1 11.5-11.4 0-5.5-.2-19.9-.3-39.1-8.4 1.9-15.9 2.7-22.6 2.7-43.1 0-52.9-33.5-52.9-33.5-10.2-26.5-24.9-33.6-24.9-33.6-19.5-13.7-.1-14.1 1.4-14.1h.1c22.5 2 34.3 23.8 34.3 23.8 11.2 19.6 26.2 25.1 39.6 25.1 10.5 0 20-3.4 25.6-6 2-14.8 7.8-24.9 14.2-30.7-49.7-5.8-102-25.5-102-113.5 0-25.1 8.7-45.6 23-61.6-2.3-5.8-10-29.2 2.2-60.8 0 0 1.6-.5 5-.5 8.1 0 26.4 3.1 56.6 24.1 17.9-5.1 37-7.6 56.1-7.7 19 .1 38.2 2.6 56.1 7.7 30.2-21 48.5-24.1 56.6-24.1 3.4 0 5 .5 5 .5 12.2 31.6 4.5 55 2.2 60.8 14.3 16.1 23 36.6 23 61.6 0 88.2-52.4 107.6-102.3 113.3 8 7.1 15.2 21.1 15.2 42.5 0 30.7-.3 55.5-.3 63 0 5.4 3.1 11.5 11.4 11.5 1.2 0 2.6-.1 4-.4C415.9 449.2 480 363.1 480 261.7 480 134.9 379.7 32 256 32z" />
                  </svg>
                </a>

                {/* <a
                    href="https://fiverr.com/abcinteractive"
                    className="mx-2                   
                    transition duration-200 transform 
                    hover:-translate-y-1 hover:scale-110"

                    aria-label="Twitter"
                  >
                    <svg className="w-5 h-5 fill-current" viewBox="0 0 400 400"><circle cx="200" cy="200" fill="#00b22d" r="200" /><g fill="#fff"><path d="M364.4 162.7c0-6.6-5.2-12-11.8-12-6.4 0-11.7 5.3-11.7 12 0 6.6 5.2 12 11.7 12 6.6.1 11.8-5.3 11.8-12zm-11.8 8.7c-4.5 0-8-3.8-8-8.7 0-4.8 3.5-8.6 8-8.6 4.6 0 8.2 3.8 8.2 8.6 0 4.9-3.6 8.7-8.2 8.7z" /><path d="M355.8 163.7c.6-.2 1.9-1.1 1.9-3 0-2.3-1.5-3.7-4-3.7h-5.3v11.3h3.5v-3.8h.9l1.6 3.8h3.8l-2.1-3.9c-.2-.6-.3-.7-.3-.7zm-3-1.6h-.9v-2.7h.9c.8 0 1.2.4 1.2 1.3.1.9-.4 1.4-1.2 1.4z" /><circle cx="104.6" cy="163" r="9" /><path d="M114 177.9H72.8v-2.7c0-5.3 5.3-5.4 8-5.4 3.1 0 4.5.3 4.5.3v-14.6s-2.8-.4-6.6-.4c-8.6 0-24.5 2.4-24.5 20.6v2.3h-7.5v13.5h7.5V220h-7v13.5H81V220h-8.2v-28.5h22.5V220h-7v13.5H121V220h-7zm70 0h-29.5v13.5h5l-6.4 20c-1.2 3.3-1.5 7.3-1.5 7.3h-.4s-.3-4-1.5-7.3l-6.4-20h5v-13.5h-29.5v13.5h6.2l15.4 42h22l15.4-42h6.2zm54.6 25.5c0-15.4-9.3-26.8-25.8-26.8-17.9 0-28.9 12.7-28.9 29 0 14.8 10.7 29.1 30.5 29.1 15 0 23.9-7.8 23.9-7.8l-6.8-12.9s-7.4 5.3-15.6 5.3c-5.9 0-11.5-3.1-12.9-10.2h35.2c-.1-.1.4-3.9.4-5.7zm-35.2-4.6c1-4.3 3.6-8.2 8.9-8.2 4.1 0 7 3.8 7 8.2zm114.1-8.1h-.2s.2-1.1.2-2.8V185c0-5.1-2.8-7.1-7.9-7.1h-17.5v13.5h5.2c1.5 0 2.4.9 2.4 2.4V220h-7.5v13.5h33.7V220h-7.5v-8.1c0-10.1 5-16.7 15.3-16.7 2.3 0 3.8.3 3.8.3v-18.3s-1.1-.2-2.2-.2c-8.4-.1-15.4 6.1-17.8 13.7zm-49.3 0h-.2s.2-1.1.2-2.8V185c0-5.1-2.8-7.1-7.9-7.1h-17.5v13.5h5.2c1.5 0 2.4.9 2.4 2.4V220h-7.5v13.5h33.7V220h-7.5v-8.1c0-10.1 5-16.7 15.3-16.7 2.3 0 3.8.3 3.8.3v-18.3s-1.1-.2-2.2-.2c-8.5-.1-15.5 6.1-17.8 13.7z" /></g></svg>
                  </a> */}
              </div>
            </div>

            <div className="mt-8 lg:mt-0 lg:w-1/2">
              <div className="flex items-center justify-center lg:justify-end">
                <div className="max-w-lg">
                  <RevealFlipUp>
                    <StaticImage
                      src="../../content/assets/selfie_with_marley_paige_meadows-300x225.jpg"
                      alt={"hard at work"}
                      className="object-cover object-center w-full h-64 rounded-md shadow"
                    />
                  </RevealFlipUp>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  SERVICES */}
      <section id="services" className="bg-gray-100 dark:bg-gray-900 pt-5">
        <div className="container px-6 py-10 mx-auto">
          <RevealSlideUp>
            <h3 className="text-3xl font-semibold text-center text-gray-800  font-family-opensans  capitalize lg:text-4xl dark:text-white
            hover:underline">
              <Link to="/services">Services
              </Link>
            </h3>
          </RevealSlideUp>
          <div className="container px-6 py-12 mx-auto">
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
              <RevealFade delay={300}>
                <div>
                  <svg className="w-8 h-8" viewBox="0 0 109.902 109.902">
                    <g>
                      <path
                        d="M106.654,15.087H3.252C1.459,15.087,0,16.545,0,18.338v57.297c0,0,0.002,0.002,0.002,0.004v2.863
		c0,1.059,0.857,1.916,1.914,1.916h42.32c-0.172,3.768-1.004,11.33-4.916,12.988c0,0-1.518,1.408,2.73,1.408h7.271h10.021h7.273
		c4.248,0,2.73-1.408,2.73-1.408c-3.912-1.658-4.746-9.221-4.916-12.988h43.594c1.061,0,1.871-0.857,1.871-1.916v-2.777
		c0-0.031,0.008-0.06,0.008-0.09V18.338C109.904,16.545,108.447,15.087,106.654,15.087z M52.869,79.786
		c0-0.895,0.725-1.619,1.619-1.619c0.895,0,1.619,0.725,1.619,1.619s-0.725,1.619-1.619,1.619
		C53.594,81.405,52.869,80.681,52.869,79.786z M106.654,75.991H3.252V18.594c0-0.254,0.205-0.461,0.459-0.461h102.482
		c0.254,0,0.461,0.207,0.461,0.461V75.991z"
                      />
                    </g>
                  </svg>

                  <h3 className="mt-4 text-xl font-semibold text-gray-800  font-family-opensans  dark:text-white">
                    Web Development
                  </h3>
                  <p className="font-family-opensans mb-1">
                    Modern web development services leveraging the latest
                    technologies such as a fast static PWA (progressive web app)
                    built with <a href="https://reactjs.org/">React JS</a>,{" "}
                    <a href="https://www.gatsbyjs.com/">Gatsby</a>, and{" "}
                    <a href="https://wordpress.org/">Wordpress</a>.
                  </p>
                  <div>
                    <Skills1 skills={skillsWebDevelopment} />
                  </div>
                </div>
              </RevealFade>
              <RevealFade delay={600}>
                <div>
                  <svg
                    className="w-8 h-8"
                    viewBox="0 0 24 24"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    stroke="#000000"
                    fill="none"
                    color="#000000"
                  >
                    <path d="M18,3 L18,21 C18,21.5522847 17.5522847,22 17,22 L7,22 C6.44771525,22 6,21.5522847 6,21 L6,3 C6,2.44771525 6.44771525,2 7,2 L17,2 C17.5522847,2 18,2.44771525 18,3 Z" />{" "}
                    <polygon points="14 2 14 3 10 3 10 2" />{" "}
                  </svg>

                  <h3 className="mt-4 text-xl font-semibold text-gray-800  font-family-opensans  dark:text-white">
                    App Development
                  </h3>
                  <p className="font-family-opensans mb-1">
                    Mobile app development in native iOS Swift or a React Native
                    app deployed on iOS, Android, or the WWW.
                  </p>
                  <div>
                    <Skills1 skills={skillsAppDevelopment} />
                  </div>
                </div>
              </RevealFade>
              <RevealFade delay={900}>
                <div>
                  <svg
                    className="w-8 h-8"
                    viewBox="0 0 24 24"
                    stroke="#000000"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.8476 13.317L9.50515 18.2798C8.70833 19.1905 7.29167 19.1905 6.49485 18.2798L2.15238 13.317C1.49259 12.563 1.49259 11.437 2.15238 10.683L6.49485 5.72018C7.29167 4.80952 8.70833 4.80952 9.50515 5.72017L13.8476 10.683C14.5074 11.437 14.5074 12.563 13.8476 13.317Z"
                      stroke="currentColor"
                    // stroke-width="1.5"
                    // stroke-linecap="round"
                    // stroke-linejoin="round"
                    />
                    <path
                      d="M13 19L17.8844 13.3016C18.5263 12.5526 18.5263 11.4474 17.8844 10.6984L13 5"
                      stroke="currentColor"
                    // stroke-width="1.5"
                    // stroke-linecap="round"
                    // stroke-linejoin="round"
                    />
                    <path
                      d="M17 19L21.8844 13.3016C22.5263 12.5526 22.5263 11.4474 21.8844 10.6984L17 5"
                      stroke="currentColor"
                    // stroke-width="1.5"
                    // stroke-linecap="round"
                    // stroke-linejoin="round"
                    />
                  </svg>
                  <h3 className="mt-4 text-xl font-semibold text-gray-800  font-family-opensans  dark:text-white">
                    Animation
                  </h3>
                  <p className="font-family-opensans mb-1">
                    Motion graphics, 3D and 2D animation, game characters, and
                    over 20 years of experience bringing things to life on
                    screen to create engaging experiences.
                  </p>
                  <div>
                    <Skills1 skills={skillsAnimation} />
                  </div>
                </div>
              </RevealFade>
            </div>
          </div>
          <div className="container px-6 py-12 mx-auto text-center">
            <Link to="/services" >
              <button className="p-5 rounded-md bg-gray-500 dark:bg-gray-900 p-4 font-semibold text-center text-gray-800  font-family-opensans  text-md lg:text-lg dark:text-white
            hover:underline
            transform transition hover:-translate-y-1 hover:scale-110 ease-in-out duration-200
            ">
                Find out more about ABC Interactive LLC development services such as a <span className="text-bold">"3 tier" overview</span> for a new custom Gatsby or NextJS web app with custom Lottie animations.
                  
              </button>
            </Link>
          </div>
        </div>
      </section>

      {/*  EXPERIENCE (past clients) */}
      <section id="clients" className="bg-white dark:bg-gray-900 pt-5">
        <div className="container px-6 py-10 mx-auto">
          <RevealSlideUp>
            <h3 className="text-3xl font-semibold text-center text-gray-800  font-family-opensans  capitalize lg:text-4xl dark:text-white">
              Experience
            </h3>
          </RevealSlideUp>
          <p className="font-family-opensans">
            I have built websites for companies large and small, educational
            games for companies such as Score! Learning Centers and the
            Rainforest Action Network. I was a technical author for 4 chapters
            on a book for Adobe Press Classroom in a book, led teams developing
            award winning training courses while working at Vitesse Learning,
            and helped build next-gen courseware during the “dot com” era of the
            bay area at The Ninth House Network.
          </p>

          <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="grid gap-5 row-gap-5 mb-8 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
              {/* BRAVE */}
              <a
                href="https://brave.com"
                target="_blank"
                aria-label="View Item"
                className="inline-block overflow-hidden duration-300 transform bg-white rounded shadow-lg hover:-translate-y-2"
              >
                <div className="flex flex-col h-full">
                  <StaticImage
                    src="../../content/assets/clients/logo_brave.png"
                    alt={"Brave Software"}
                    className="object-contain w-full h-24"
                  />
                  <div className="flex-grow border border-t-0 rounded-b bg-gray-100">
                    <div className="p-5">
                      <h6 className="mb-2 font-semibold leading-5 font-family-opensans">
                        Brave Software, Inc.
                      </h6>
                      <p className="uppercase text-xs text-gray-900 mb-1 font-family-opensans">
                        WEB DEVELOPER AND PHP ENGINEER CONTRACT
                      </p>
                      <p className="text-sm text-gray-900 font-family-opensans">
                        Responsibilities included working with the team to help
                        update, monitor, and maintain the brave.com and
                        basicattentiontoken.org sites. Specific projects
                        included 4 WordPress plugins, site localization,
                        building and launching the new home page in November
                        2019, posting blog articles and press releases in a
                        timely manor as needed, and fielding many general
                        website updates and maintenance.
                      </p>
                    </div>
                  </div>
                </div>
              </a>

              {/* LANDISPR */}
              <a
                href="https://www.landispr.com"
                target="_blank"
                aria-label="View Item"
                className="inline-block overflow-hidden duration-300 transform bg-white rounded shadow-lg hover:-translate-y-2"
              >
                <div className="flex flex-col h-full">
                  <StaticImage
                    src="../../content/assets/clients/logo_landispr.png"
                    alt={"Landis Communications, Inc."}
                    className="object-contain w-full h-24"
                  />
                  <div className="flex-grow border border-t-0 rounded-b bg-gray-100">
                    <div className="p-5">
                      <h6 className="mb-2 font-semibold leading-5 font-family-opensans">
                        Landis Communications, Inc.
                      </h6>
                      <p className="uppercase text-xs text-gray-900 mb-1 font-family-opensans">
                        WEB MASTER
                      </p>
                      <p className="text-sm text-gray-900 font-family-opensans">
                        From 2008 - 2015, I maintained, setup the blog
                        "Backtalk", updated functionality, and posted daily
                        press release and client updates for the website of
                        Landis Communications Inc. I worked directly with
                        company founder and president, David Landis, and the
                        kind and very professional team at his 30 year old
                        leading San Francisco Consumer &amp; B2B PR/Marketing
                        Agency.
                      </p>
                    </div>
                  </div>
                </div>
              </a>

              {/* INTUIT */}
              <a
                href="https://www.intuit.com"
                target="_blank"
                aria-label="View Item"
                className="inline-block overflow-hidden duration-300 transform bg-white rounded shadow-lg hover:-translate-y-2"
              >
                <div className="flex flex-col h-full">
                  <StaticImage
                    src="../../content/assets/clients/logo_intuit.png"
                    alt={"Intuit"}
                    className="object-contain w-full h-24"
                  />
                  <div className="flex-grow border border-t-0 rounded-b bg-gray-100">
                    <div className="p-5">
                      <h6 className="mb-2 font-semibold leading-5 font-family-opensans">
                        Intuit
                      </h6>
                      <p className="uppercase text-xs text-gray-900 mb-1 font-family-opensans">
                        OVER 100 PROJECTS FOR INTUIT® DESIGNED BY MUNSON DESIGN
                      </p>
                      <p className="text-sm text-gray-900 font-family-opensans">
                        From 2008 - 2015, I worked under contract with Munson
                        Design to develop around 10 entire websites, 40+ landing
                        pages or mini-sites, over 30 animated banner
                        advertisements, many more animations, and newsletter
                        layouts for Intuit® and their products such as
                        QuickBooks®, including:
                      </p>
                      <ul className="list-disc list-inside text-xs mt-1">
                        <li>Intuit® Security website (2010)</li>
                        <li>Full Service Payroll website (2010-2015)</li>
                        <li>Intuit® Awards website (2010)</li>
                        <li>Intuit® Career website (2011)</li>
                        <li>
                          Intuit® Fortune website (2010, 2012, 2013, &amp; 2014)
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </a>

              {/* FINELITE */}
              <a
                href="https://www.finelite.com"
                target="_blank"
                aria-label="View Item"
                className="inline-block overflow-hidden duration-300 transform bg-white rounded shadow-lg hover:-translate-y-2"
              >
                <div className="flex flex-col h-full">
                  <StaticImage
                    src="../../content/assets/clients/logo_finelite_lighting.png"
                    alt={"Finelite Lighting"}
                    className="object-contain w-full h-24"
                  />
                  <div className="flex-grow border border-t-0 rounded-b bg-gray-100">
                    <div className="p-5">
                      <h6 className="mb-2 font-semibold leading-5 font-family-opensans">
                        Finelite Lighting
                      </h6>
                      <p className="uppercase text-xs text-gray-900 mb-1 font-family-opensans">
                        WEBSITE AND CART FOR FINELITE® BETTER LIGHTING DESIGNED
                        BY RICHTER DESIGN
                      </p>
                      <p className="text-sm text-gray-900 font-family-opensans">
                        In 2011, Richter Design contracted me work with them to
                        develop a CMS (Content Management System) integrated
                        with shopping cart for the award winning lighting
                        solution company, Finelite® Better Lighting. As a large
                        and technologically competitive company leading in
                        energy saving lighting, Finelite® required a media rich
                        and easy to navigate every growing site to present their
                        products and services.
                      </p>
                    </div>
                  </div>
                </div>
              </a>

              {/* ADOBE */}
              <a
                href="https://www.adobe.com"
                target="_blank"
                aria-label="View Item"
                className="inline-block overflow-hidden duration-300 transform bg-white rounded shadow-lg hover:-translate-y-2"
              >
                <div ref={catSectionStart} className="flex flex-col h-full">
                  <StaticImage
                    src="../../content/assets/clients/logo_adobe_press.png"
                    alt={"Adobe Press"}
                    className="object-contain w-full h-24"
                  />
                  <div className="flex-grow border border-t-0 rounded-b bg-gray-100">
                    <div className="p-5">
                      <h6 className="mb-2 font-semibold leading-5 font-family-opensans">
                        Adobe Press
                      </h6>
                      <p className="uppercase text-xs text-gray-900 mb-1 font-family-opensans">
                        CONTRIBUTED TO 4 CHAPTERS IN ADOBE FLASH CS3
                        PROFESSIONAL CLASSROOM IN A BOOK
                      </p>
                      <p className="text-sm text-gray-900 font-family-opensans">
                        In 2006 under contract with Munson Design, I helped
                        author 4 chapters in "Adobe Flash CS3 Professional
                        Classroom in a Book". After 5 years in the industry
                        building hundreds of interactive Flash animations and
                        online courseware, I very much enjoyed helping distill
                        the secrets of Flash with it's powerful intuitive IDE.
                        One chapter I worked on covered ActionScript 3 which was
                        exciting at the time as Flash evolved to a powerful
                        programming language.
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>

      {/*  TESTIMONIALS */}
      <section id="testimonials" className="bg-gray-100 dark:bg-gray-900 pt-5">
        <div className="rounded=lg bg-gray-600 max-w-2xl"></div>

        <div
          className="flex flex-col h-full relative pb-48
         max-w-2xl m-auto "
        >
          <div className="absolute top-0 left-0 mt-3 ml-4 md:mt-5 md:ml-12">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="text-indigo-200 fill-current w-12 h-12 md:w-16 md:h-16"
              viewBox="0 0 24 24"
            >
              <path d="M6.5 10c-.223 0-.437.034-.65.065.069-.232.14-.468.254-.68.114-.308.292-.575.469-.844.148-.291.409-.488.601-.737.201-.242.475-.403.692-.604.213-.21.492-.315.714-.463.232-.133.434-.28.65-.35.208-.086.39-.16.539-.222.302-.125.474-.197.474-.197L9.758 4.03c0 0-.218.052-.597.144C8.97 4.222 8.737 4.278 8.472 4.345c-.271.05-.56.187-.882.312C7.272 4.799 6.904 4.895 6.562 5.123c-.344.218-.741.4-1.091.692C5.132 6.116 4.723 6.377 4.421 6.76c-.33.358-.656.734-.909 1.162C3.219 8.33 3.02 8.778 2.81 9.221c-.19.443-.343.896-.468 1.336-.237.882-.343 1.72-.384 2.437-.034.718-.014 1.315.028 1.747.015.204.043.402.063.539.017.109.025.168.025.168l.026-.006C2.535 17.474 4.338 19 6.5 19c2.485 0 4.5-2.015 4.5-4.5S8.985 10 6.5 10zM17.5 10c-.223 0-.437.034-.65.065.069-.232.14-.468.254-.68.114-.308.292-.575.469-.844.148-.291.409-.488.601-.737.201-.242.475-.403.692-.604.213-.21.492-.315.714-.463.232-.133.434-.28.65-.35.208-.086.39-.16.539-.222.302-.125.474-.197.474-.197L20.758 4.03c0 0-.218.052-.597.144-.191.048-.424.104-.689.171-.271.05-.56.187-.882.312-.317.143-.686.238-1.028.467-.344.218-.741.4-1.091.692-.339.301-.748.562-1.05.944-.33.358-.656.734-.909 1.162C14.219 8.33 14.02 8.778 13.81 9.221c-.19.443-.343.896-.468 1.336-.237.882-.343 1.72-.384 2.437-.034.718-.014 1.315.028 1.747.015.204.043.402.063.539.017.109.025.168.025.168l.026-.006C13.535 17.474 15.338 19 17.5 19c2.485 0 4.5-2.015 4.5-4.5S19.985 10 17.5 10z" />
            </svg>
          </div>

          <div
            className="relative z-10 py-6  px-20
            md:py-10  text-center "
          >
            <p
              className="text-gray-600 serif font-normal italic text-lg 
            md:text-2xl "
            >
              "Andrew Chew helped us develop and maintain LCI's very first
              website for nearly a decade. His work was helpful, smart and
              efficient. Thanks to his help, our website continues to generate
              numerous business leads. Highest recommendation!"
            </p>
            <p className="text-gray-600 text-sm px-2 md:px-20  text-left ">
              &mdash;David Landis
            </p>
            <p className="text-gray-600 text-sm px-2 md:px-20 text-left">
              President,{" "}
              <a
                className="hover:text-blue-900 hover:underline text-blue-600"
                href="https://www.landispr.com/"
                target="_blank"
              >
                Landis Communications Inc. (LCI)
              </a>
            </p>
          </div>
        </div>
      </section>

      {/*  CAT WALK */}
      <section className="relative bg-white dark:bg-gray-900 pt-5">
        <div
          id="lottie-cat"
          className={`
              w-full z-10
              ${isMobile ? "height-100px" : "height-200px"}
              absolute bottom-0 pt-20         
                `}
        >
          <div
            ref={lottieCatContainer}
            className={`
          origin-top           
          ${isMobile ? "height-100px" : "height-200px"}
          `}
          ></div>
        </div>
      </section>

      {/*  SQUIRRLED APPS sections */}
      <section ref={catSectionStop} className="bg-white dark:bg-gray-900">
        <div className="container px-6 pb-10 pt-24 mx-auto">
          <RevealSlideUp>
            <h3 className="text-3xl font-semibold text-center text-gray-800  font-family-opensans  capitalize lg:text-4xl dark:text-white">
              Squirreled® Math Games
            </h3>
          </RevealSlideUp>
          <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-16 md:grid-cols-2 xl:grid-cols-3">
            <RevealFlipRight delay={200}>
              <div className="flex flex-col items-center p-6 space-y-3 text-center bg-gray-100 rounded-xl dark:bg-gray-800">
                <span className="inline-block p-3 text-blue-500 bg-blue-100 rounded-full dark:text-white dark:bg-blue-500">
                  <StaticImage
                    src="../../content/assets/logo_squirreledclassroom.png"
                    alt={"Squirreled® Classroom"}
                    className="w-32 h-32 sm:w-48 sm:h-48 md:w-40 md:h-40 lg:w-48 lg:h-48 rounded-2xl"
                  />
                </span>

                <h3 className="text-2xl font-semibold text-gray-700 capitalize dark:text-white">
                  Squirreled® Classroom
                </h3>

                <p className="text-gray-600 dark:text-gray-400 font-family-opensans ">
                  Squirreled® Classroom is a brand new original math practice
                  board game app now available on Android devices as well as Mac and iPad with
                  Apple&apos;s Schoolwork integration! Explore prime factors
                  of numbers 1-99 in fun way integrated with gameplay that helps
                  develop basic math table proficiency!
                </p>

                {/* <div className="mt-6">
                  <a
                    className=" btn text-white"
                    href="https://apps.apple.com/us/app/squirreledclassroom/id1523707421"
                  >
                    <button
                      className="mb-5 inline-flex items-center justify-center px-5 py-3 text-base font-medium
                     text-white bg-gradient-to-r from-gray-700 to-gray-900
                      hover:from-gray-600 hover:to-gray-600 w-auto
                      transform transition hover:-translate-y-1 hover:scale-110 ease-in-out duration-200
                      rounded-lg shadow "
                    >
                      <svg
                        className="w-6 h-6 mx-2 fill-current"
                        x="0px"
                        y="0px"
                        viewBox="0 0 512 512"
                      >
                        <g>
                          <g>
                            <path
                              d="M407,0H105C47.103,0,0,47.103,0,105v302c0,57.897,47.103,105,105,105h302c57.897,0,105-47.103,105-105V105
                                        C512,47.103,464.897,0,407,0z M482,407c0,41.355-33.645,75-75,75H105c-41.355,0-75-33.645-75-75V105c0-41.355,33.645-75,75-75h302
                                        c41.355,0,75,33.645,75,75V407z"
                            ></path>
                          </g>
                        </g>
                        <g>
                          <g>
                            <path
                              d="M305.646,123.531c-1.729-6.45-5.865-11.842-11.648-15.18c-11.936-6.892-27.256-2.789-34.15,9.151L256,124.166
                                        l-3.848-6.665c-6.893-11.937-22.212-16.042-34.15-9.151h-0.001c-11.938,6.893-16.042,22.212-9.15,34.151l18.281,31.664
                                        L159.678,291H110.5c-13.785,0-25,11.215-25,25c0,13.785,11.215,25,25,25h189.86l-28.868-50h-54.079l85.735-148.498
                                        C306.487,136.719,307.375,129.981,305.646,123.531z"
                            ></path>
                          </g>
                        </g>
                        <g>
                          <g>
                            <path
                              d="M401.5,291h-49.178l-55.907-96.834l-28.867,50l86.804,150.348c3.339,5.784,8.729,9.921,15.181,11.65
                                        c2.154,0.577,4.339,0.863,6.511,0.863c4.332,0,8.608-1.136,12.461-3.361c11.938-6.893,16.042-22.213,9.149-34.15L381.189,341
                                        H401.5c13.785,0,25-11.215,25-25C426.5,302.215,415.285,291,401.5,291z"
                            ></path>
                          </g>
                        </g>
                        <g>
                          <g>
                            <path
                              d="M119.264,361l-4.917,8.516c-6.892,11.938-2.787,27.258,9.151,34.15c3.927,2.267,8.219,3.345,12.458,3.344
                                        c8.646,0,17.067-4.484,21.693-12.495L176.999,361H119.264z"
                            ></path>
                          </g>
                        </g>
                      </svg>
                      <span className="mx-2">Get it on the App Store</span>
                    </button>
                  </a>

                  <a
                    className=" btn text-white"
                    href="https://play.google.com/store/apps/details?id=net.abcinteractive.SquirreledClassroom"
                  >
                    <button
                      className="mb-5 inline-flex items-center justify-center  px-5 py-3 text-base font-medium
                     text-white bg-gradient-to-r  from-blue-700 to-blue-900
                     hover:from-blue-600 hover:to-blue-600  w-auto
                      transform transition hover:-translate-y-1 hover:scale-110 ease-in-out duration-200
                      rounded-lg shadow "
                    >
                      <svg
                        className="w-6 h-6 mx-2 fill-current"
                        viewBox="-28 0 512 512.00075"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m432.320312 215.121094-361.515624-208.722656c-14.777344-8.53125-32.421876-8.53125-47.203126 0-.121093.070312-.230468.148437-.351562.21875-.210938.125-.421875.253906-.628906.390624-14.175782 8.636719-22.621094 23.59375-22.621094 40.269532v417.445312c0 17.066406 8.824219 32.347656 23.601562 40.878906 7.390626 4.265626 15.496094 6.398438 23.601563 6.398438s16.214844-2.132812 23.601563-6.398438l361.519531-208.722656c14.777343-8.53125 23.601562-23.8125 23.601562-40.878906s-8.824219-32.347656-23.605469-40.878906zm-401.941406 253.152344c-.21875-1.097657-.351562-2.273438-.351562-3.550782v-417.445312c0-2.246094.378906-4.203125.984375-5.90625l204.324219 213.121094zm43.824219-425.242188 234.21875 135.226562-52.285156 54.539063zm-6.480469 429.679688 188.410156-196.527344 54.152344 56.484375zm349.585938-201.835938-80.25 46.332031-60.125-62.714843 58.261718-60.773438 82.113282 47.40625c7.75 4.476562 8.589844 11.894531 8.589844 14.875s-.839844 10.398438-8.589844 14.875zm0 0"></path>
                      </svg>
                      <span className="mx-2">Get it on Google Play</span>
                    </button>
                  </a>
                </div> */}
              </div>
            </RevealFlipRight>

            <RevealFlipRight delay={2000}>
              <div className="flex flex-col items-center p-6 space-y-3 text-center bg-gray-100 rounded-xl dark:bg-gray-800">
                <span className="inline-block p-3 text-blue-500 bg-blue-100 rounded-full dark:text-white dark:bg-blue-500">
                  <StaticImage
                    src="../../content/assets/logo_squirreledworld.png"
                    alt={"Squirreled® World"}
                    className="w-32 h-32 sm:w-48 sm:h-48 md:w-40 md:h-40 lg:w-48 lg:h-48 rounded-2xl"
                  />
                </span>

                <h3 className="text-2xl font-semibold text-gray-700 capitalize dark:text-white">
                  Squirreled® World
                </h3>

                <p className="text-gray-600 dark:text-gray-400 font-family-opensans ">
                  Squirreled® World, released in 2018, is an arcade style action
                  adventure game with 8 large "open world" style levels that
                  become more challenging as you progress (levels 2-8 require
                  full game unlock). Built with Xcode and programmed nearly
                  entirely in Swift with SpriteKit and GamePlayKit.
                </p>
                {/* <a
                  className="btn text-white"
                  href="https://itunes.apple.com/us/app/squirreledworld/id1315458127"
                >
                  <button
                    className="mb-5 inline-flex items-center justify-center px-5 py-3 text-base font-medium
                     text-white bg-gradient-to-r from-gray-700 to-gray-900
                      hover:from-gray-600 hover:to-gray-600 w-auto
                      transform transition hover:-translate-y-1 hover:scale-110 ease-in-out duration-200
                      rounded-lg shadow "
                  >
                    <svg
                      className="w-6 h-6 mx-2 fill-current"
                      x="0px"
                      y="0px"
                      viewBox="0 0 512 512"
                    >
                      <g>
                        <g>
                          <path
                            d="M407,0H105C47.103,0,0,47.103,0,105v302c0,57.897,47.103,105,105,105h302c57.897,0,105-47.103,105-105V105
                                        C512,47.103,464.897,0,407,0z M482,407c0,41.355-33.645,75-75,75H105c-41.355,0-75-33.645-75-75V105c0-41.355,33.645-75,75-75h302
                                        c41.355,0,75,33.645,75,75V407z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M305.646,123.531c-1.729-6.45-5.865-11.842-11.648-15.18c-11.936-6.892-27.256-2.789-34.15,9.151L256,124.166
                                        l-3.848-6.665c-6.893-11.937-22.212-16.042-34.15-9.151h-0.001c-11.938,6.893-16.042,22.212-9.15,34.151l18.281,31.664
                                        L159.678,291H110.5c-13.785,0-25,11.215-25,25c0,13.785,11.215,25,25,25h189.86l-28.868-50h-54.079l85.735-148.498
                                        C306.487,136.719,307.375,129.981,305.646,123.531z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M401.5,291h-49.178l-55.907-96.834l-28.867,50l86.804,150.348c3.339,5.784,8.729,9.921,15.181,11.65
                                        c2.154,0.577,4.339,0.863,6.511,0.863c4.332,0,8.608-1.136,12.461-3.361c11.938-6.893,16.042-22.213,9.149-34.15L381.189,341
                                        H401.5c13.785,0,25-11.215,25-25C426.5,302.215,415.285,291,401.5,291z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M119.264,361l-4.917,8.516c-6.892,11.938-2.787,27.258,9.151,34.15c3.927,2.267,8.219,3.345,12.458,3.344
                                        c8.646,0,17.067-4.484,21.693-12.495L176.999,361H119.264z"
                          ></path>
                        </g>
                      </g>
                    </svg>
                    <span className="mx-2">Get it on the App Store</span>
                  </button>
                </a> */}
              </div>
            </RevealFlipRight>

            <RevealFlipRight delay={3000}>
              <div className="flex flex-col items-center p-6 space-y-3 text-center bg-gray-100 rounded-xl dark:bg-gray-800">
                <span className="inline-block p-3 text-blue-500 bg-blue-100 rounded-full dark:text-white dark:bg-blue-500">
                  <StaticImage
                    src="../../content/assets/logo_squirreled.png"
                    alt={"Squirreled®"}
                    className="w-32 h-32 sm:w-48 sm:h-48 md:w-40 md:h-40 lg:w-48 lg:h-48 rounded-2xl"
                  />
                </span>

                <h3 className="text-2xl font-semibold text-gray-700 capitalize dark:text-white">
                  Squirreled®
                </h3>

                <p className="text-gray-600 dark:text-gray-400 font-family-opensans ">
                  Squirreled® 2.0 with new Apple watch app included! Brand new
                  story and animations, choose your character, larger level maps
                  and much more! Completely free app download with no ads.
                  Originally released in 2013.
                </p>
                {/* <a
                  className=" btn text-white"
                  href="https://itunes.apple.com/us/app/squirreled/id611572353"
                >
                  <button
                    className="mb-5 inline-flex items-center justify-center px-5 py-3 text-base font-medium
                     text-white bg-gradient-to-r from-gray-700 to-gray-900
                      hover:from-gray-600 hover:to-gray-600 w-auto
                      transform transition hover:-translate-y-1 hover:scale-110 ease-in-out duration-200
                      rounded-lg shadow "
                  >
                    <svg
                      className="w-6 h-6 mx-2 fill-current"
                      x="0px"
                      y="0px"
                      viewBox="0 0 512 512"
                    >
                      <g>
                        <g>
                          <path
                            d="M407,0H105C47.103,0,0,47.103,0,105v302c0,57.897,47.103,105,105,105h302c57.897,0,105-47.103,105-105V105
                                        C512,47.103,464.897,0,407,0z M482,407c0,41.355-33.645,75-75,75H105c-41.355,0-75-33.645-75-75V105c0-41.355,33.645-75,75-75h302
                                        c41.355,0,75,33.645,75,75V407z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M305.646,123.531c-1.729-6.45-5.865-11.842-11.648-15.18c-11.936-6.892-27.256-2.789-34.15,9.151L256,124.166
                                        l-3.848-6.665c-6.893-11.937-22.212-16.042-34.15-9.151h-0.001c-11.938,6.893-16.042,22.212-9.15,34.151l18.281,31.664
                                        L159.678,291H110.5c-13.785,0-25,11.215-25,25c0,13.785,11.215,25,25,25h189.86l-28.868-50h-54.079l85.735-148.498
                                        C306.487,136.719,307.375,129.981,305.646,123.531z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M401.5,291h-49.178l-55.907-96.834l-28.867,50l86.804,150.348c3.339,5.784,8.729,9.921,15.181,11.65
                                        c2.154,0.577,4.339,0.863,6.511,0.863c4.332,0,8.608-1.136,12.461-3.361c11.938-6.893,16.042-22.213,9.149-34.15L381.189,341
                                        H401.5c13.785,0,25-11.215,25-25C426.5,302.215,415.285,291,401.5,291z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M119.264,361l-4.917,8.516c-6.892,11.938-2.787,27.258,9.151,34.15c3.927,2.267,8.219,3.345,12.458,3.344
                                        c8.646,0,17.067-4.484,21.693-12.495L176.999,361H119.264z"
                          ></path>
                        </g>
                      </g>
                    </svg>
                    <span className="mx-2">Get it on the App Store</span>
                  </button>
                </a> */}
              </div>
            </RevealFlipRight>
          </div>
        </div>
      </section>

      {/*  CONTACT */}
      <section id="contact" className="bg-gray-100 dark:bg-gray-900">
        <div className="container px-6 py-10 mx-auto">
          <RevealSlideUp>
            <h3 className="text-3xl font-semibold text-center text-gray-800  font-family-opensans  capitalize lg:text-4xl dark:text-white">
              Contact ABC Interactive LLC
            </h3>
          </RevealSlideUp>
          <div className="grid grid-cols-1 gap-6 mt-6 sm:grid-cols-2 md:grid-cols-3">
            <RevealFade delay={300}>
              <div
                className="flex flex-col items-center px-4 py-3 text-gray-700 transition-colors duration-200 
            transform rounded-md dark:text-gray-200"
              >
                <svg
                  className="w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" />
                </svg>

                <span className="mt-2">USA</span>
              </div>
            </RevealFade>

            <RevealFade delay={600}>
              <div
                className="flex flex-col items-center px-4 py-3 text-gray-700 transition-colors duration-200 
            transform rounded-md dark:text-gray-200"
              >
                {/* <svg
                  className="w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                </svg>

                <span className="mt-2"></span> */}
              </div>
            </RevealFade>

            <RevealFade delay={900}>
              <a
                href="mailto:info@abc-interactive.com"
                className="
            flex flex-col items-center px-4 py-3 text-gray-700 transition duration-200 
            transform rounded-md dark:text-gray-200 bg-blue-200 hover:bg-blue-500 dark:hover:bg-blue-200
            hover:-translate-y-1 hover:scale-110
            "
              >
                <svg
                  className="w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>

                <span className="mt-2">info@abc-interactive.com</span>
              </a>
            </RevealFade>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default HomePage

const Skills1 = (props: { skills: SkillsType[] }) => {
  return (
    <div className="flex flex-row flex-wrap w-full">
      {props.skills.map((skill: SkillsType, index: number) => (
        <div key={`_${index}`} className="rounded-md bg-gray-800 m-1 shadow-md">
          <p className="text-xs text-white p-2">{skill.name}</p>
          <p className="bg-gray-600 text-xs text-gray-400 text-right px-1 rounded-md rounded-t-none">
            {skill.years} {skill.years > 1 ? "years" : "year"}
          </p>
        </div>
      ))}
    </div>
  )
}
